import { post, get} from '../../washtraxClient';

const ticketList = data => {
  return get (`yard/ticket/list`);
};

const fetchServiceDetails = () => {
  return get (`service/list-for-selectbox`);
};

const fetchServiceDetailsByEqType = (id) => {
  return get (`service/list-for-selectbox/all/${id}`);
};

const inspectFinish = data => {
  return post ('yard/action', data);
};

const ticketCompleteYard = data => {
  return post ('yard/ticket/complete', data);
};
const fetchServiceDetailsPost = () => {
  return get (`service/list-for-selectbox/post`);
};
const fetchFoodList=()=>{
  return get('service/foodgrade/list')
}
const yardFetchFoodList=()=>{
  return get('service/foodgrade/list?fromYard=true')
}
export default {
  ticketList,
  fetchServiceDetails,
  yardFetchFoodList,
  fetchFoodList,
  fetchServiceDetailsByEqType,
  inspectFinish,
  ticketCompleteYard,
  fetchServiceDetailsPost
};
