import LoginManager from './loginManager';
import StationManager from './stationManager';
import YardManager from './yardManager';
import CustomerManager from './customerManager';
import UserManager from './userManager';
import DocumentManager from './documentManager';
import PoManager from './PoManager';
import LocationManager from './LocationManager';
import CommonManager from './commonManager';
import ServiceList from './serviceManager';
import myYardManager from './My_Yard/myYardManager';
import attentionManager from './attentionManager';
import CheckInOutManager from './checkInOutManager';
import InYardManager from './My_Yard/InYardManager';
import closedoutManager from './My_Yard/closedoutManager';
import ipdYardManager from './Ipd_Yard/TicketListManager';
import CustomerSelect from './reports/CustomerSelect';
import CustomerList from './reports/CustomerList';
import DashboardManager from './dashboardManager';
import MailManager from './mailManager';

export default {
  ...LoginManager,
  ...StationManager,
  ...YardManager,
  ...CustomerManager,
  ...UserManager,
  ...DocumentManager,
  ...PoManager,
  ...LocationManager,
  ...CommonManager,
  ...ServiceList,
  ...myYardManager,
  ...attentionManager,
  ...CheckInOutManager,
  ...InYardManager,
  ...closedoutManager,
  ...ipdYardManager,
  ...CustomerSelect,
  ...CustomerList,
  ...DashboardManager,
  ...MailManager,
};
