import {post, get} from '../washtraxClient';

const purchaseList = data => {
  return get (`po/list`);
};

const customerList = data => {
  return get (`customer/list`);
};

const getPOServiceList = data => {
  return get (`service/list-for-selectbox`);
};

const checkponumber = (poNumber, custId) => {
  return get (`po/uniquecheck?poNumber=${poNumber}&custId=${custId}`);
};

const addPurchaseOrder = data => {
  return post ('po/add', data);
};

export default {
  purchaseList,
  customerList,
  getPOServiceList,
  checkponumber,
  addPurchaseOrder,
};
